import jsPDF from 'jspdf'
import { CvElement, CvElementPDF } from '@/store/commonFormInput/types'
import { ApplicationData, BaseData, Language } from '@/store/student/types'
import i18n from '@/i18n'
import { Qualification, TalentProfileViewType } from '@/store/types'
import { localFormat } from '@/common/datePicker'

const getLayoutAttributes = (doc: jsPDF) => {
  // 1. get all necessary constants for the PDF
  // eslint-disable-next-line new-cap
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
  const lMargin = 15 // left margin in mm
  const rMargin = 20 // right margin in mm
  const bMargin = 15
  const pdfInMM = 210
  const contentWidth = (pdfInMM - lMargin - rMargin)
  return { pageWidth, pageHeight, lMargin, rMargin, bMargin, pdfInMM, contentWidth }
}

const getNewYAxis = (yPos: number, len: number) => {
  // return yPos + (len + size)
  return yPos + (len * 5)
}

const setFooter = (doc: jsPDF, page: number) => {
  const { lMargin, bMargin, pageWidth, pageHeight } = getLayoutAttributes(doc)
  doc.setFontSize(8).setTextColor('#666')
  doc.text(`- ${page} -`, pageWidth / 2, pageHeight - bMargin - 10, { align: 'center' })
  doc.text(i18n.t('student.cv-data.copy-right').toString(), pageWidth / 2, pageHeight - bMargin, { align: 'center' })
}

const getName = (baseInformation: BaseData) => {
  return `${baseInformation.firstname} ${baseInformation.lastname}`
}

const getContact = (baseInformation: BaseData, applicationData: ApplicationData) => {
  return `${applicationData.street ? applicationData.street + (applicationData.number ? ' ' + applicationData.number + ' ' : ' ') + (applicationData.supplement ? ' ' + applicationData.supplement + ', ' : ', ') : ','}${applicationData.postalCode ? applicationData.postalCode + ' ' : ''}${applicationData.city ? applicationData.city + ', ' : ''}${baseInformation.email ? baseInformation.email + ', ' : ''}${applicationData.phone ? applicationData.phone : ''}`
}

const setContactFooter = (doc: jsPDF, baseInformation: BaseData, applicationData: ApplicationData) => {
  const { pdfInMM, lMargin, rMargin, bMargin, pageWidth, pageHeight, contentWidth } = getLayoutAttributes(doc)
  doc.setFontSize(10).setTextColor('#333')
  const lines = doc.splitTextToSize(`${baseInformation.firstname} ${baseInformation.lastname} - ` + getContact(baseInformation, applicationData), contentWidth)
  doc.text(lines, pageWidth / 2, pageHeight - bMargin - 20, { align: 'center' })
}

const newLine = (doc: jsPDF, xPos: number, yPos: number) => {
  doc.text('\n', xPos, yPos)
}

export const classic = (doc: jsPDF, items: CvElementPDF[], baseInformation: BaseData, applicationData: ApplicationData, languages: Language[], interests: {value: string}[], qualifications: Qualification[]) => {
  const { pageWidth, pageHeight, lMargin, rMargin, pdfInMM, contentWidth } = getLayoutAttributes(doc)
  let yPos = 25
  let lines = ''
  let size = 24
  let page = 1

  // Name and contact
  doc.setFontSize(size)
  lines = doc.splitTextToSize(getName(baseInformation), contentWidth)
  doc.text(lines, pageWidth / 2, yPos, { align: 'center' })
  yPos = getNewYAxis(yPos, lines.length)
  newLine(doc, lMargin, yPos)
  yPos = getNewYAxis(yPos, 1)
  size = 11
  doc.setFontSize(size)
  doc.text(getContact(baseInformation, applicationData), pageWidth / 2, yPos, { align: 'center' })
  yPos = getNewYAxis(yPos, lines.length)
  newLine(doc, lMargin, yPos)
  yPos = getNewYAxis(yPos, 1)

  // CV Data
  items.map((item: CvElementPDF) => {
    if (item.items && item.items.length > 0) {
      item.items.map((it: CvElement, i: number) => {
        const headline = doc.splitTextToSize(i18n.t(`student.CV_TYPES.${item.type}`).toString(), contentWidth)
        const description = doc.splitTextToSize(it.cvDescription ? it.cvDescription : '', contentWidth)
        const title = doc.splitTextToSize(item.type === 'SCHOOL' ? i18n.t('student.SCHOOL_DEGREES.' + it.cvTitle).toString() : it.cvTitle, contentWidth)
        const subTitle = doc.splitTextToSize(`${it.cvWhere ? it.cvWhere + ', ' : ''}${it.cvStudyDegree ? it.cvStudyDegree + ', ' : ''}${it.cvStudyField ? it.cvStudyField + ', ' : ''}${it.cvFrom} - ${it.cvTo ? it.cvTo : i18n.t('student.cv-data.today').toString()}`, contentWidth)

        const newYPos = getNewYAxis(getNewYAxis(getNewYAxis(yPos, title.length), subTitle.length), description.length)
        if (newYPos > pageHeight - 30) {
          setFooter(doc, page++)
          doc.addPage()
          yPos = 25
        }

        if (i === 0) {
          size = 14
          doc.setFontSize(size)
          doc.setFont('Helvetica', 'bold')
          doc.setTextColor('#88BD21')
          doc.text(headline, lMargin, yPos)
          yPos = getNewYAxis(yPos, headline.length)
          newLine(doc, lMargin, yPos)
          yPos = getNewYAxis(yPos, 1)
        }

        // title
        size = 12
        doc.setFontSize(size)
        doc.setFont('Helvetica', 'bold')
        doc.setTextColor('#000')
        doc.text(title, lMargin, yPos)
        yPos = getNewYAxis(yPos, title.length)

        // where, from - to
        size = 10
        doc.setFontSize(size)
        doc.setFont('Helvetica', 'normal')
        doc.text(subTitle, lMargin, yPos)
        yPos = getNewYAxis(yPos, subTitle.length)

        // description
        size = 11
        doc.setFont('Helvetica', 'normal')
        doc.setFontSize(size)
        doc.setTextColor('#000')
        doc.text(description, lMargin, yPos)
        yPos = getNewYAxis(yPos, description.length)

        newLine(doc, lMargin, yPos)
        yPos = getNewYAxis(yPos, 1)
      })
    }
  })

  // Languages
  if (languages.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor('#88BD21')
    lines = doc.splitTextToSize(i18n.t('student.cv-data.languages').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    languages.map(lang => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(lang.value ? `${i18n.t('LANGUAGES.' + lang.value).toString()} ${lang.level ? '(' + i18n.t('LANGUAGE_LEVELS.' + lang.level).toString() + ')' : ''}` : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)

      if (yPos > pageHeight - 50) {
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })

    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
  }

  // Qualifications
  if (qualifications.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor('#88BD21')
    lines = doc.splitTextToSize(i18n.t('main-menu-student.qualifications').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    qualifications.map(qualification => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(qualification.synonym ? qualification.synonym + ' (' + i18n.t('student.cv-data.language-level') + ': ' + qualification.level + ' %)' : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)

      if (yPos > pageHeight - 50) {
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })
  }

  // Interests
  if (interests.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor('#88BD21')
    lines = doc.splitTextToSize(i18n.t('student.cv-data.personal-interests').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    interests.map(interest => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(interest.value ? interest.value : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)

      if (yPos > pageHeight - 50) {
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })
  }

  setFooter(doc, page++)

  return doc
}

export const elegant = (doc: jsPDF, items: CvElementPDF[], baseInformation: BaseData, applicationData: ApplicationData, languages: Language[], interests: {value: string}[], qualifications: Qualification[]) => {
  const { pageWidth, pageHeight, lMargin, rMargin, pdfInMM, contentWidth } = getLayoutAttributes(doc)
  let yPos = 25
  let lines = ''
  let size = 20
  let page = 1

  // Name and contact
  doc.setFontSize(size)
  doc.setFont('Times New Roman', 'bold')
  lines = doc.splitTextToSize(getName(baseInformation), contentWidth)
  doc.text(lines, pageWidth / 2, yPos, { align: 'center' })
  yPos = getNewYAxis(yPos, lines.length)
  newLine(doc, lMargin, yPos)
  yPos = getNewYAxis(yPos, 1)
  size = 11
  doc.setFontSize(size)
  doc.text('', lMargin, yPos)
  doc.setLineWidth(0.5)
  doc.line(lMargin, yPos, pageWidth - rMargin, yPos)
  yPos = getNewYAxis(yPos, lines.length)
  newLine(doc, lMargin, yPos)
  yPos = getNewYAxis(yPos, 1)

  // CV Data
  items.map((item: CvElementPDF) => {
    item.items.map((it: CvElement, i: number) => {
      const headline = doc.splitTextToSize(i18n.t(`student.CV_TYPES.${item.type}`).toString(), contentWidth)
      const description = doc.splitTextToSize(it.cvDescription ? it.cvDescription : '', contentWidth)
      const title = doc.splitTextToSize(`${it.cvTitle ? (item.type === 'SCHOOL' ? i18n.t('student.SCHOOL_DEGREES.' + it.cvTitle).toString() : it.cvTitle) + ', ' : ''}${it.cvWhere ? it.cvWhere + ', ' : ''}${it.cvStudyDegree ? it.cvStudyDegree + ', ' : ''}${it.cvStudyField ? it.cvStudyField + ', ' : ''}${it.cvFrom} - ${it.cvTo ? it.cvTo : i18n.t('student.cv-data.today').toString()}`, contentWidth)

      const newYPos = getNewYAxis(getNewYAxis(yPos, title.length), description.length)
      if (newYPos > pageHeight - 30) {
        setContactFooter(doc, baseInformation, applicationData)
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }

      if (i === 0) {
        size = 14
        doc.setFontSize(size)
        doc.setFont('Times New Roman', 'bold')
        doc.setTextColor('#000')
        doc.text(headline, pageWidth / 2, yPos, { align: 'center' })
        yPos = getNewYAxis(yPos, headline.length)
        newLine(doc, lMargin, yPos)
        yPos = getNewYAxis(yPos, 1)
      }

      // title
      doc.setFontSize(11)
      doc.setFont('Helvetica', 'bold')
      doc.setTextColor('#000')
      doc.text(title, pageWidth / 2, yPos, { align: 'center' })
      yPos = getNewYAxis(yPos, title.length)

      // description
      size = 10
      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(size)
      doc.setTextColor('#000')
      doc.text(description, pageWidth / 2, yPos, { align: 'center' })
      yPos = getNewYAxis(yPos, description.length)

      newLine(doc, lMargin, yPos)
      yPos = getNewYAxis(yPos, 1)
    })
  })

  // Languages
  if (languages.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setTextColor('#000')
    doc.setFont('Times New Roman', 'bold')
    lines = doc.splitTextToSize(i18n.t('student.cv-data.languages').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    languages.map(lang => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(lang.value ? `${i18n.t('LANGUAGES.' + lang.value).toString()} ${lang.level ? '(' + i18n.t('LANGUAGE_LEVELS.' + lang.level).toString() + ')' : ''}` : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)
      newLine(doc, lMargin, yPos)
      yPos = getNewYAxis(yPos, 1)

      if (yPos > pageHeight - 50) {
        setContactFooter(doc, baseInformation, applicationData)
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })
  }

  // Qualifications
  if (qualifications.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setFont('Times New Roman', 'bold')
    lines = doc.splitTextToSize(i18n.t('main-menu-student.qualifications').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    qualifications.map(qualification => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(qualification.synonym ? qualification.synonym + ' (' + i18n.t('student.cv-data.language-level') + ': ' + qualification.level + ' %)' : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)

      if (yPos > pageHeight - 50) {
        setContactFooter(doc, baseInformation, applicationData)
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })
  }

  // Interests
  if (interests.length > 0) {
    lines = ''
    size = 14
    doc.setFontSize(size)
    doc.setFont('Times New Roman', 'bold')
    lines = doc.splitTextToSize(i18n.t('student.cv-data.personal-interests').toString(), contentWidth)
    doc.text(lines, lMargin, yPos)
    yPos = getNewYAxis(yPos, lines.length)
    newLine(doc, lMargin, yPos)
    yPos = getNewYAxis(yPos, 1)
    interests.map(interest => {
      // description
      size = 11
      doc.setFontSize(size)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('#000')
      lines = doc.splitTextToSize(interest.value ? interest.value : '', contentWidth)
      doc.text(lines, lMargin, yPos)
      yPos = getNewYAxis(yPos, lines.length)

      if (yPos > pageHeight - 50) {
        setContactFooter(doc, baseInformation, applicationData)
        setFooter(doc, page++)
        doc.addPage()
        yPos = 25
      }
    })
  }

  setContactFooter(doc, baseInformation, applicationData)
  setFooter(doc, page++)

  return doc
}

export const prepareCVDownload = function (cvStyle: string, cvData: CvElement[], languages: Language[], interests: {value: string}[], baseData: BaseData, applicationData: ApplicationData, qualifications: Qualification[]): jsPDF {
  const seqCV = ['STUDY', 'ABROAD', 'APPRENTICESHIP', 'SCHOOL', 'JOB', 'PROJECT', 'INTERNSHIP', 'STUDENT_ASSISTANT', 'OTHER']

  const cvDataList = seqCV.map((type: string) => {
    return { type, items: cvData.filter((it: CvElement) => it.cvType === type).map((it: CvElement) => ({ ...it, cvFrom: localFormat(it.cvFrom, i18n.locale), cvTo: localFormat(it.cvTo, i18n.locale) })) }
  })

  // 1. get all necessary constants for the PDF
  let doc
  const filteredLanguages = languages.filter(lang => lang.value)
  const filteredInterests = interests.filter(interest => interest.value)

  switch (cvStyle) {
    case 'CLASSIC':
      // eslint-disable-next-line new-cap
      doc = classic(new jsPDF(), cvDataList, baseData, applicationData, filteredLanguages, filteredInterests, qualifications)
      break
    case 'ELEGANT':
      // eslint-disable-next-line new-cap
      doc = elegant(new jsPDF(), cvDataList, baseData, applicationData, filteredLanguages, filteredInterests, qualifications)
      break
    default:
      // eslint-disable-next-line new-cap
      doc = classic(new jsPDF(), cvDataList, baseData, applicationData, filteredLanguages, filteredInterests, qualifications)
  }
  return doc
}

export const prepareCvDownload = function (cvStyle: string, talent: TalentProfileViewType): jsPDF {
  const baseData: BaseData = {
    salutation: talent.salutation ? talent.salutation : '',
    title: talent.title ? talent.title : '',
    firstname: talent.firstname ? talent.firstname : '',
    lastname: talent.lastname ? talent.lastname : '',
    email: talent.email ? talent.email : '',
    fallbackMail: '',
    courseOfStudy: ''
  }

  const applicationData: ApplicationData = {
    city: talent.city ? talent.city : '',
    dateOfBirth: talent.dateOfBirth ? talent.dateOfBirth : '',
    description: talent.description ? talent.description : '',
    jobStatus: talent.jobStatus ? talent.jobStatus : '',
    mobile: talent.mobile ? talent.mobile : '',
    number: talent.number ? talent.number : '',
    phone: talent.phone ? talent.phone : '',
    postalCode: talent.postalCode ? talent.postalCode : '',
    socialMedia: talent.socialMedia ? talent.socialMedia : '',
    street: talent.street ? talent.street : '',
    supplement: talent.supplement ? talent.supplement : '',
    website: talent.website ? talent.website : ''
  }

  const interests = talent.interests?.map(interest => {
    return { value: interest }
  })

  return prepareCVDownload(
    cvStyle,
    talent.cvItems ? talent.cvItems : [],
    talent.languages ? talent.languages : [],
    interests as {value: string}[],
    baseData,
    applicationData,
    talent.qualifications ? talent.qualifications : []
  )
}

export default {
  classic,
  elegant,
  prepareCVDownload
}
